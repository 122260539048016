<template>
  <div class="height-100 max_box">
    <!-- <div class="audio-box">
      <audio src="audio/bg.mp3" ref="bgAudio"></audio>
      <audio :src="audioSrcList[0]" ref="audio1"></audio>
      <audio :src="audioSrcList[1]" ref="audio2"></audio>
      <audio :src="audioSrcList[2]" ref="audio3"></audio>
      <audio :src="audioSrcList[3]" ref="audio4"></audio>
      <audio :src="audioSrcList[4]" ref="audio5"></audio>
      <audio :src="audioSrcList[5]" ref="audio6"></audio>
      <audio :src="audioSrcList[6]" ref="audio7"></audio>
      <audio :src="audioSrcList[7]" ref="audio8"></audio>
    </div> -->
    <div class="card animate__animated animate__fadeIn duration" id="card1">
      <div class="content_box">
        <img class="img1" src="../../assets/img/yanbao1.png" alt="" />
        <p class="name_title">灯塔夏洛克</p>
        <div class="info_wrapper">
          <div class="yanbao animate__fadeIn none" id="title1">
            <img src="../../assets/img/yanbao2.png" alt="" />
          </div>
          <p id="title2" class="animate__fadeIn none">{{ extra_param_1 }}</p>
          <ul>
            <li>
              机构观点
              <div></div>
            </li>
            <li>
              热门个股
              <div></div>
            </li>
            <li>
              评级目标价
              <div></div>
            </li>
            <li>
              关注度榜单
              <div></div>
            </li>
          </ul>
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card2"
    >
      <div class="content_box">
        <div class="top_title">
          <p>
            {{ card_data_1.orgnamedisc[0] }}
            <span>•</span>
            <span>精选研报</span>
          </p>
          <p>{{ extra_param_1 }}</p>
        </div>
        <div
          class="stock"
          v-for="(item, index) in card_data_1.secuabbr"
          :key="index"
        >
          <div class="stock_name">{{ item }}</div>
          <p>{{ card_data_1.shiyi[index] }}；{{ card_data_1.title[index] }}</p>
          <!-- <p>{{ card_data_1.title[index] }}</p> -->
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card3"
    >
      <div class="content_box">
        <div class="top_title">
          <p>
            {{ card_data_2.orgnamedisc[0] }}
            <span>•</span>
            <span>精选研报</span>
          </p>
          <p>{{ extra_param_1 }}</p>
        </div>
        <div
          class="stock"
          v-for="(item, index) in card_data_2.secuabbr"
          :key="index"
        >
          <div class="stock_name">{{ item }}</div>
          <p>{{ card_data_2.shiyi[index] }}；{{ card_data_2.title[index] }}</p>
          <!-- <p>{{ card_data_2.title[index] }}</p> -->
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card4"
    >
      <div class="content_box">
        <div class="top_title">
          <p>
            {{ card_data_3.orgnamedisc[0] }}
            <span>•</span>
            <span>精选研报</span>
          </p>
          <p>{{ extra_param_1 }}</p>
        </div>
        <div
          class="stock"
          v-for="(item, index) in card_data_3.secuabbr"
          :key="index"
        >
          <div class="stock_name">{{ item }}</div>
          <p>{{ card_data_3.shiyi[index] }}；{{ card_data_3.title[index] }}</p>
          <!-- <p>{{ card_data_3.title[index] }}</p> -->
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card5"
    >
      <div class="content_box">
        <div class="top_title">
          <p>
            {{ card_data_4.orgnamedisc[0] }}
            <span>•</span>
            <span>精选研报</span>
          </p>
          <p>{{ extra_param_1 }}</p>
        </div>
        <div
          class="stock"
          v-for="(item, index) in card_data_4.secuabbr"
          :key="index"
        >
          <div class="stock_name">{{ item }}</div>
          <p>{{ card_data_4.shiyi[index] }}；{{ card_data_4.title[index] }}</p>
          <!-- <p>{{ card_data_4.title[index] }}</p> -->
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card6"
    >
      <div class="content_box">
        <div class="top_title">
          <p>
            {{ card_data_5.orgnamedisc[0] }}
            <span>•</span>
            <span>精选研报</span>
          </p>
          <p>{{ extra_param_1 }}</p>
        </div>
        <div
          class="stock"
          v-for="(item, index) in card_data_5.secuabbr"
          :key="index"
        >
          <div class="stock_name">{{ item }}</div>
          <p>{{ card_data_5.shiyi[index] }}；{{ card_data_5.title[index] }}</p>
          <!-- <p>{{ card_data_5.title[index] }}</p> -->
        </div>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration common_card none"
      id="card7"
    >
      <div class="content_box">
        <div class="top_title">
          <p>个股机构关注度</p>
          <p>{{ extra_param_1 }}</p>
        </div>
        <ul class="title_list">
          <li style="text-align: left">
            <p>股票</p>
            <p>名称</p>
          </li>
          <li>
            <p>机构</p>
            <p>家数</p>
          </li>
          <li>
            <p>研报</p>
            <p>篇数</p>
          </li>
          <li>
            <p>目标价</p>
            <p>最高/最低</p>
          </li>
          <li>
            <p>今日</p>
            <p>收盘价</p>
          </li>
        </ul>
        <ul
          class="item_list"
          v-for="(item, index) in card_data_6.sec_name"
          :key="index"
        >
          <li style="text-align: left">{{ item }}</li>
          <li>{{ card_data_6.n_proforstat[index] }}</li>
          <li>{{ card_data_6.yb_ps[index] }}</li>
          <li>{{ card_data_6.concatt[index] }}</li>
          <li>{{ card_data_6.c_price[index] }}</li>
        </ul>
        <div class="logo-box">
          <img src="../../assets/img/yanbaologo.png" alt="" />
        </div>
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card8"
    >
      <div class="width-100 height-100">
        <video
          class="end_video"
          src="video/xlk_10.mov"
          muted
          width="100%"
          height="100%"
          ref="endVideo"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams, formatToDate, GetParams2 } from "../../utils/common";
import ApiServe from "@/api/index";
export default {
  name: "Template52",
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      options: {
        useEasing: true, // 过渡动画效果，默认ture
        useGrouping: false, // 千分位效果，例：1000->1,000。默认true
        //separator: ',',   // 使用千分位时分割符号
        decimals: 2, // 小数位分割符号
        prefix: "", // 前置符号
        suffix: "", // 后置符号，可汉字
      },
      extra_param_1: [],

      recordtimeAll: null,
      // device_id:null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      card_data_1: {
        orgnamedisc: [],
        secuabbr: [],
        shiyi: [],
        title: [],
      },
      card_data_2: {
        orgnamedisc: [],
        secuabbr: [],
        shiyi: [],
        title: [],
      },
      card_data_3: {
        orgnamedisc: [],
        secuabbr: [],
        shiyi: [],
        title: [],
      },
      card_data_4: {
        orgnamedisc: [],
        secuabbr: [],
        shiyi: [],
        title: [],
      },
      card_data_5: {
        orgnamedisc: [],
        secuabbr: [],
        shiyi: [],
        title: [],
      },
      card_data_6: {
        sec_name: [],
        n_proforstat: [],
        yb_ps: [],
        concatt: [],
        c_price: [],
      },
    };
  },
  created() {
    console.log(666);
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
    this.getAudio();
  },
  computed: {
    allReady() {
      const { extra_param_1, audioSrcList } = this;
      return {
        extra_param_1,
        audioSrcList,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  watch: {
    allReady(val) {
      console.log(val);
      if (val.extra_param_1.length > 0 && val.audioSrcList.length > 0) {
        setTimeout(() => {
          if (this.ip && this.port) {
            const params = {
              ip: this.ip,
              port: this.port,
              offset_x: this.offset_x,
              offset_y: this.offset_y,
              width: this.width,
              height: this.height,
            };

            ApiServe.recordstartPc(this.newIpPort, params)
              .then(({ code }) => {})
              .catch((err) => {
                ApiServe.recordreleasePc(this.newIpPort, {
                  ip: this.ip,
                  port: this.port,
                });
              });
            this.load();
          } else {
            // alert("没获取到ip,port");
            this.load();
          }
        }, 4000);
      }
    },
  },
  methods: {
    formatToDate,
    getData() {
      let params = {
        model_id: 52,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 52,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.extra_param_1 = data.extra_param_1[0];
            this.card_data_1 = data.chart_data_1[0].data;
            console.log(this.card_data_1);
            // setTimeout(() => {
            //   $("#title1").fadeIn(1000);
            // }, 1000);

            // setTimeout(() => {
            //   $("#title2").fadeIn(1000);
            // }, 3000);

            this.card_data_2 = data.chart_data_2[0].data;
            this.card_data_3 = data.chart_data_3[0].data;
            this.card_data_4 = data.chart_data_4[0].data;
            this.card_data_5 = data.chart_data_5[0].data;
            this.card_data_6 = data.chart_data_6[0].data;

            if (this.extra_param_1.length == 0 && this.ip) {
              ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },

    // 获取旁白
    getAudio() {
      let params = {
        model_id: 52,
      };
      if (this.url_extra_params) {
        params = {
          model_id: 52,
          url_extra_params: this.url_extra_params,
        };
      }
      ApiServe.getVideoDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            this.audioData = data || [];
            this.audioSrcList = data.map((item) => {
              return item.audio_link;
            });
            this.audioTimeList = data.map((item) => {
              return item.scene_duration;
            });
            this.recordtimeAll =
              this.audioTimeList.reduce(function (prev, curr, idx, arr) {
                return prev + curr;
              }) +
              this.audioTimeList.length +
              2;

            if (this.audioSrcList.length == 0 && this.ip) {
              ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
            }
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.releasepcportPc({ ip: this.ip, port: this.port });
          }
        });
    },
    // 数字滚动
    startNumber(vdom, number, point) {
      let num = new CountUp(vdom, 0, number, point, 4, this.options);
      num.start();
    },

    endRecord() {
      let arr = [
        1,
        5,
        6 + this.audioTimeList[1],
        7 + this.audioTimeList[1] + this.audioTimeList[2],
        8 +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3],
        9 +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          this.audioTimeList[4],
        10 +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          this.audioTimeList[4] +
          this.audioTimeList[5],
        11 +
          this.audioTimeList[1] +
          this.audioTimeList[2] +
          this.audioTimeList[3] +
          this.audioTimeList[4] +
          this.audioTimeList[5] +
          this.audioTimeList[6],
      ];
      let audiosOrderList = this.audioData.map((item, index) => {
        return {
          audioLink: item.audio_link,
          timeIndex: arr[index],
          audioLen: item.audio_length,
        };
      });
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: this.recordtimeAll,
        videmBgm:
          "https://idtcdn.oss-cn-hangzhou.aliyuncs.com/Cube/VideoBgm/bg.mp3",
        audiosOrderList: audiosOrderList,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    load() {
      // setTimeout(() => {
      // this.$refs.audio1.play();
      setTimeout(() => {
        $("#title1").fadeIn(1000);
      }, 1000);

      setTimeout(() => {
        $("#title2").fadeIn(1000);
      }, 3000);
      // }, 1000);

      setTimeout(() => {
        $("#card1").addClass("animate__fadeOut");
      }, 4000);

      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        // this.$refs.audio2.play();
      }, 5000); //间隔

      setTimeout(() => {
        $("#card2").addClass("animate__fadeOut");
      }, (5 + this.audioTimeList[1]) * 1000);

      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        // this.$refs.audio3.play();
      }, (6 + this.audioTimeList[1]) * 1000); //间隔

      setTimeout(() => {
        $("#card3").addClass("animate__fadeOut");
      }, (6 + this.audioTimeList[1] + this.audioTimeList[2]) * 1000);

      setTimeout(() => {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        // this.$refs.audio4.play();
      }, (7 + this.audioTimeList[1] + this.audioTimeList[2]) * 1000); //间隔

      setTimeout(() => {
        $("#card4").addClass("animate__fadeOut");
      }, (7 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000);

      setTimeout(() => {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        // this.$refs.audio5.play();
      }, (8 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3]) * 1000); //间隔

      setTimeout(() => {
        $("#card5").addClass("animate__fadeOut");
      }, (8 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000);

      setTimeout(() => {
        $("#card5").addClass("none");
        $("#card6").removeClass("none");
        // this.$refs.audio6.play();
      }, (9 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4]) * 1000); //间隔

      setTimeout(() => {
        $("#card6").addClass("animate__fadeOut");
      }, (9 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4] + this.audioTimeList[5]) * 1000);

      setTimeout(() => {
        $("#card6").addClass("none");
        $("#card7").removeClass("none");
        // this.$refs.audio7.play();
      }, (10 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4] + this.audioTimeList[5]) * 1000);

      setTimeout(() => {
        $("#card7").addClass("animate__fadeOut");
      }, (10 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6]) * 1000);

      setTimeout(() => {
        $("#card7").addClass("none");
        $("#card8").removeClass("none");
        this.$refs.endVideo.play();
        // this.$refs.audio8.play();
      }, (11 + this.audioTimeList[1] + this.audioTimeList[2] + this.audioTimeList[3] + this.audioTimeList[4] + this.audioTimeList[5] + this.audioTimeList[6]) * 1000);

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, (this.recordtimeAll + 1) * 1000);
      }
    },
  },
};
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
  li {
    list-style: none;
  }
}
.max_box {
  font-size: 12px;
  background: url("../../assets/img/bgyanbao2.png") no-repeat center center;
  font-family: "HarmonyOS_Sans_SC_Regular";
}
#card1 {
  background: url("../../assets/img/bgyanbao2.png") no-repeat center center;
  background-size: cover;
}
.card {
  background: none;
  overflow: hidden;
  box-sizing: border-box;
  background: url("../../assets/img/bgyanbao2.png") no-repeat center center;
  background-size: cover;
  .content_box {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 55px 88px;
    font-size: 36px;
    color: #3b2c1a;
  }
}

.logo-box {
  width: 405px;
  opacity: 1;
  bottom: 15%;
}

#card1 {
  .img1 {
    display: block;
    position: absolute;
    top: 8%;
    left: 285px;
  }
  .name_title {
    font-size: 30px;
    font-style: italic;
    font-weight: 800;
    margin-top: 42%;
    text-align: center;
    margin-bottom: 48px;
  }

  .info_wrapper {
    .yanbao {
      width: 304.5px;
      margin: 4px auto;
      img {
        width: 100%;
        margin: 10px auto;
      }
    }
    p:first-of-type {
      text-align: center;
      font-weight: bold;
      font-size: 48px;
      margin-bottom: 64px;
    }
    ul {
      width: 48%;
      margin: auto;
      text-align: center;

      li {
        font-size: 36px;
        font-weight: bold;
        color: #3c3c3c;
        margin-bottom: 50px;
        position: relative;
        div {
          width: 160px;
          height: 16px;
          background: #ff8646;
          position: absolute;
          top: 35px;
          left: 50px;
          z-index: -1;
        }
      }
    }
  }
}
#card7 {
  .content_box {
    padding: 55px 60px;
  }
  ul {
    width: 100%;
    display: flex;
    li {
      // float: left;
      width: 23%;
      text-align: right;
    }
  }
  .title_list {
    margin-bottom: 32px;
    li {
      font-size: 24px;
    }

    li:nth-of-type(2) {
      width: 12%;
    }
    li:nth-of-type(3) {
      width: 12%;
    }
    li:nth-of-type(4) {
      width: 30%;
    }
  }
  .item_list {
    font-size: 28px;
    li {
      margin-bottom: 42px;
      color: #e5431c;
      font-weight: 500;
    }
    li:first-of-type {
      color: #3b2c1a;
      font-weight: bold;
    }

    li:nth-of-type(2) {
      width: 12%;
    }
    li:nth-of-type(3) {
      width: 12%;
    }
    li:nth-of-type(4) {
      width: 30%;
      font-size: 26px;
    }
  }
}

.common_card {
  .top_title {
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
    p:first-of-type {
      font-size: 48px;
      font-weight: bold;
      line-height: 82px;
      color: #3b2c1a;
      span:first-of-type {
        margin-left: -20px;
        display: inline-block;
      }
      span:nth-of-type(2) {
        margin-left: -10px;
        display: inline-block;
      }
    }
    p:nth-of-type(2) {
      font-size: 28px;
      color: #3b2c1a;
      font-weight: bold;
    }
  }
  .stock {
    margin-bottom: 25px;
    .stock_name {
      font-weight: bold;
      color: #3b2c1a;
      line-height: 61px;
    }
    p:first-of-type {
      font-size: 28px;
      color: #674a28;
      margin-bottom: 16px;
    }
    p:nth-of-type(2) {
      font-size: 28px;
      color: #674a28;
    }
  }
  .logo-box {
    bottom: 48px;
  }
}
</style>